export const ICD10_CODES = [
  {
    type: 'icd10',
    id: 'icd10-a00',
    code: 'A00',
    display: 'Cholera',
    createdAt: 1584374840883,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a00.0',
    code: 'A00.0',
    display: 'Cholera due to Vibrio cholerae 01, biovar cholerae',
    createdAt: 1584374840883,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a00.1',
    code: 'A00.1',
    display: 'Cholera due to Vibrio cholerae 01, biovar eltor',
    createdAt: 1584374840883,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a00.9',
    code: 'A00.9',
    display: 'Cholera, unspecified',
    createdAt: 1584374840884,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a01',
    code: 'A01',
    display: 'Typhoid and paratyphoid fevers',
    createdAt: 1584374840884,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a01.0',
    code: 'A01.0',
    display: 'Typhoid fever',
    createdAt: 1584374840884,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a01.1',
    code: 'A01.1',
    display: 'Paratyphoid fever A',
    createdAt: 1584374840884,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a01.2',
    code: 'A01.2',
    display: 'Paratyphoid fever B',
    createdAt: 1584374840884,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a01.3',
    code: 'A01.3',
    display: 'Paratyphoid fever C',
    createdAt: 1584374840885,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a01.4',
    code: 'A01.4',
    display: 'Paratyphoid fever, unspecified',
    createdAt: 1584374840885,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a02',
    code: 'A02',
    display: 'Other salmonella infections',
    createdAt: 1584374840885,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a02.0',
    code: 'A02.0',
    display: 'Salmonella enteritis',
    createdAt: 1584374840889,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a02.1',
    code: 'A02.1',
    display: 'Salmonella sepsis',
    createdAt: 1584374840889,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a02.2',
    code: 'A02.2',
    display: 'Localized salmonella infections',
    createdAt: 1584374840889,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a02.8',
    code: 'A02.8',
    display: 'Other specified salmonella infections',
    createdAt: 1584374840890,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a02.9',
    code: 'A02.9',
    display: 'Salmonella infection, unspecified',
    createdAt: 1584374840890,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a03',
    code: 'A03',
    display: 'Shigellosis',
    createdAt: 1584374840890,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a03.0',
    code: 'A03.0',
    display: 'Shigellosis due to Shigella dysenteriae',
    createdAt: 1584374840890,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a03.1',
    code: 'A03.1',
    display: 'Shigellosis due to Shigella flexneri',
    createdAt: 1584374840890,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a03.2',
    code: 'A03.2',
    display: 'Shigellosis due to Shigella boydii',
    createdAt: 1584374840891,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a03.3',
    code: 'A03.3',
    display: 'Shigellosis due to Shigella sonnei',
    createdAt: 1584374840891,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a03.8',
    code: 'A03.8',
    display: 'Other shigellosis',
    createdAt: 1584374840891,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a03.9',
    code: 'A03.9',
    display: 'Shigellosis, unspecified',
    createdAt: 1584374840891,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a04',
    code: 'A04',
    display: 'Other bacterial intestinal infections',
    createdAt: 1584374840892,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a04.0',
    code: 'A04.0',
    display: 'Enteropathogenic Escherichia coli infection',
    createdAt: 1584374840892,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a04.1',
    code: 'A04.1',
    display: 'Enterotoxigenic Escherichia coli infection',
    createdAt: 1584374840892,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a04.2',
    code: 'A04.2',
    display: 'Enteroinvasive Escherichia coli infection',
    createdAt: 1584374840892,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a04.3',
    code: 'A04.3',
    display: 'Enterohaemorrhagic Escherichia coli infection',
    createdAt: 1584374840892,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a04.4',
    code: 'A04.4',
    display: 'Other intestinal Escherichia coli infections',
    createdAt: 1584374840893,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a04.5',
    code: 'A04.5',
    display: 'Campylobacter enteritis',
    createdAt: 1584374840893,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a04.6',
    code: 'A04.6',
    display: 'Enteritis due to Yersinia enterocolitica',
    createdAt: 1584374840893,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a04.7',
    code: 'A04.7',
    display: 'Enterocolitis due to Clostridium difficile',
    createdAt: 1584374840893,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a04.8',
    code: 'A04.8',
    display: 'Other specified bacterial intestinal infections',
    createdAt: 1584374840893,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a04.9',
    code: 'A04.9',
    display: 'Bacterial intestinal infection, unspecified',
    createdAt: 1584374840894,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a05',
    code: 'A05',
    display: 'Other bacterial foodborne intoxications, not elsewhere classified',
    createdAt: 1584374840894,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a05.0',
    code: 'A05.0',
    display: 'Foodborne staphylococcal intoxication',
    createdAt: 1584374840894,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a05.1',
    code: 'A05.1',
    display: 'Botulism',
    createdAt: 1584374840895,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a05.2',
    code: 'A05.2',
    display: 'Foodborne Clostridium perfringens [Clostridium welchii] intoxication',
    createdAt: 1584374840897,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a05.3',
    code: 'A05.3',
    display: 'Foodborne Vibrio parahaemolyticus intoxication',
    createdAt: 1584374840897,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a05.4',
    code: 'A05.4',
    display: 'Foodborne Bacillus cereus intoxication',
    createdAt: 1584374840897,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a05.8',
    code: 'A05.8',
    display: 'Other specified bacterial foodborne intoxications',
    createdAt: 1584374840898,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a05.9',
    code: 'A05.9',
    display: 'Bacterial foodborne intoxication, unspecified',
    createdAt: 1584374840898,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a06',
    code: 'A06',
    display: 'Amoebiasis',
    createdAt: 1584374840898,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a06.0',
    code: 'A06.0',
    display: 'Acute amoebic dysentery',
    createdAt: 1584374840898,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a06.1',
    code: 'A06.1',
    display: 'Chronic intestinal amoebiasis',
    createdAt: 1584374840899,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a06.2',
    code: 'A06.2',
    display: 'Amoebic nondysenteric colitis',
    createdAt: 1584374840899,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a06.3',
    code: 'A06.3',
    display: 'Amoeboma of intestine',
    createdAt: 1584374840899,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a06.4',
    code: 'A06.4',
    display: 'Amoebic liver abscess',
    createdAt: 1584374840899,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a06.5',
    code: 'A06.5',
    display: 'Amoebic lung abscess',
    createdAt: 1584374840899,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a06.6',
    code: 'A06.6',
    display: 'Amoebic brain abscess',
    createdAt: 1584374840899,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a06.7',
    code: 'A06.7',
    display: 'Cutaneous amoebiasis',
    createdAt: 1584374840900,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a06.8',
    code: 'A06.8',
    display: 'Amoebic infection of other sites',
    createdAt: 1584374840900,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a06.9',
    code: 'A06.9',
    display: 'Amoebiasis, unspecified',
    createdAt: 1584374840900,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a07',
    code: 'A07',
    display: 'Other protozoal intestinal diseases',
    createdAt: 1584374840900,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a07.0',
    code: 'A07.0',
    display: 'Balantidiasis',
    createdAt: 1584374840900,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a07.1',
    code: 'A07.1',
    display: 'Giardiasis [lambliasis]',
    createdAt: 1584374840901,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a07.2',
    code: 'A07.2',
    display: 'Cryptosporidiosis',
    createdAt: 1584374840901,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a07.3',
    code: 'A07.3',
    display: 'Isosporiasis',
    createdAt: 1584374840901,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a07.8',
    code: 'A07.8',
    display: 'Other specified protozoal intestinal diseases',
    createdAt: 1584374840901,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a07.9',
    code: 'A07.9',
    display: 'Protozoal intestinal disease, unspecified',
    createdAt: 1584374840901,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a08',
    code: 'A08',
    display: 'Viral and other specified intestinal infections',
    createdAt: 1584374840902,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a08.0',
    code: 'A08.0',
    display: 'Rotaviral enteritis',
    createdAt: 1584374840902,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a08.1',
    code: 'A08.1',
    display: 'Acute gastroenteropathy due to Norwalk agent',
    createdAt: 1584374840902,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a08.2',
    code: 'A08.2',
    display: 'Adenoviral enteritis',
    createdAt: 1584374840902,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a08.3',
    code: 'A08.3',
    display: 'Other viral enteritis',
    createdAt: 1584374840903,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a08.4',
    code: 'A08.4',
    display: 'Viral intestinal infection, unspecified',
    createdAt: 1584374840903,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a08.5',
    code: 'A08.5',
    display: 'Other specified intestinal infections',
    createdAt: 1584374840903,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a09',
    code: 'A09',
    display: 'Other gastroenteritis and colitis of infectious and unspecified origin',
    createdAt: 1584374840904,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a09.0',
    code: 'A09.0',
    display: 'Other and unspecified gastroenteritis and colitis of infectious origin',
    createdAt: 1584374840904,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a09.9',
    code: 'A09.9',
    display: 'Gastroenteritis and colitis of unspecified origin',
    createdAt: 1584374840904,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a15',
    code: 'A15',
    display: 'Respiratory tuberculosis, bacteriologically and histologically confirmed',
    createdAt: 1584374840904,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a15.0',
    code: 'A15.0',
    display: 'Tuberculosis of lung, confirmed by sputum microscopy with or without culture',
    createdAt: 1584374840904,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a15.1',
    code: 'A15.1',
    display: 'Tuberculosis of lung, confirmed by culture only',
    createdAt: 1584374840904,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a15.2',
    code: 'A15.2',
    display: 'Tuberculosis of lung, confirmed histologically',
    createdAt: 1584374840905,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a15.3',
    code: 'A15.3',
    display: 'Tuberculosis of lung, confirmed by unspecified means',
    createdAt: 1584374840905,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a15.4',
    code: 'A15.4',
    display: 'Tuberculosis of intrathoracic lymph nodes, confirmed bacteriologically and histologically',
    createdAt: 1584374840905,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a15.5',
    code: 'A15.5',
    display: 'Tuberculosis of larynx, trachea and bronchus, confirmed bacteriologically and histologically',
    createdAt: 1584374840905,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a15.6',
    code: 'A15.6',
    display: 'Tuberculous pleurisy, confirmed bacteriologically and histologically',
    createdAt: 1584374840905,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a15.7',
    code: 'A15.7',
    display: 'Primary respiratory tuberculosis, confirmed bacteriologically and histologically',
    createdAt: 1584374840906,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a15.8',
    code: 'A15.8',
    display: 'Other respiratory tuberculosis, confirmed bacteriologically and histologically',
    createdAt: 1584374840906,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a15.9',
    code: 'A15.9',
    display: 'Respiratory tuberculosis unspecified, confirmed bacteriologically and histologically',
    createdAt: 1584374840906,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a16',
    code: 'A16',
    display: 'Respiratory tuberculosis, not confirmed bacteriologically or histologically',
    createdAt: 1584374840906,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a16.0',
    code: 'A16.0',
    display: 'Tuberculosis of lung, bacteriologically and histologically negative',
    createdAt: 1584374840906,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a16.1',
    code: 'A16.1',
    display: 'Tuberculosis of lung, bacteriological and histological examination not done',
    createdAt: 1584374840907,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a16.2',
    code: 'A16.2',
    display: 'Tuberculosis of lung, without mention of bacteriological or histological confirmation',
    createdAt: 1584374840907,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a16.3',
    code: 'A16.3',
    display: 'Tuberculosis of intrathoracic lymph nodes, without mention of bacteriological or histological confirmation',
    createdAt: 1584374840907,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a16.4',
    code: 'A16.4',
    display: 'Tuberculosis of larynx, trachea and bronchus, without mention of bacteriological or histological confirmation',
    createdAt: 1584374840907,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a16.5',
    code: 'A16.5',
    display: 'Tuberculous pleurisy, without mention of bacteriological or histological confirmation',
    createdAt: 1584374840907,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a16.7',
    code: 'A16.7',
    display: 'Primary respiratory tuberculosis without mention of bacteriological or histological confirmation',
    createdAt: 1584374840907,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a16.8',
    code: 'A16.8',
    display: 'Other respiratory tuberculosis, without mention of bacteriological or histological confirmation',
    createdAt: 1584374840908,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a16.9',
    code: 'A16.9',
    display: 'Respiratory tuberculosis unspecified, without mention of bacteriological or histological confirmation',
    createdAt: 1584374840908,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a17',
    code: 'A17',
    display: 'Tuberculosis of nervous system',
    createdAt: 1584374840908,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a17.0',
    code: 'A17.0',
    display: 'Tuberculous meningitis',
    createdAt: 1584374840908,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a17.1',
    code: 'A17.1',
    display: 'Meningeal tuberculoma',
    createdAt: 1584374840908,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a17.8',
    code: 'A17.8',
    display: 'Other tuberculosis of nervous system',
    createdAt: 1584374840908,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a17.9',
    code: 'A17.9',
    display: 'Tuberculosis of nervous system, unspecified',
    createdAt: 1584374840909,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a18',
    code: 'A18',
    display: 'Tuberculosis of other organs',
    createdAt: 1584374840909,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a18.0',
    code: 'A18.0',
    display: 'Tuberculosis of bones and joints',
    createdAt: 1584374840909,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a18.1',
    code: 'A18.1',
    display: 'Tuberculosis of genitourinary system',
    createdAt: 1584374840909,
    _nonce: 17,
  },
  {
    type: 'icd10',
    id: 'icd10-a18.2',
    code: 'A18.2',
    display: 'Tuberculous peripheral lymphadenopathy',
    createdAt: 1584374840909,
    _nonce: 17,
  },
];
